import React from 'react';
import { convertToHTML } from 'draft-convert';
import { convertFromRaw, type RawDraftContentState } from 'draft-js';

import { ContentContainer } from './DraftjsReader.sc';

interface DraftjsReaderProp {
  draftjsObject?: RawDraftContentState;
}

export const DraftjsReader = (props: DraftjsReaderProp) => {
  const { draftjsObject } = props;
  if (!draftjsObject) {
    return null;
  }

  const contentState = convertFromRaw(draftjsObject);

  const html = convertToHTML({
    blockToHTML: (block) => {
      switch (block.type) {
        /**
         *  In the future if Employer Squad support more type such as Heading1, Heading2, blockquote...
         *  We can define those here:
         *  Example:
         *  case 'header-one':
              return {
              start: '<div class="typography-h1">',
              end: '</div>'
            };
         */
        case 'section':
          return {
            start: '<section>',
            end: '</section>',
          };
        case 'code':
          return {
            start: '<pre><code>',
            end: '</code></pre>',
          };
        case 'article':
          return {
            start: '<article>',
            end: '</article>',
          };
        case 'styled':
          return {
            start: '<style>',
            end: '</style>',
          };
        default:
          return undefined;
      }
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === 'LINK') {
        const { url, targetOption, target } = entity.data;
        if (url?.startsWith('http://') || url?.startsWith('https://')) {
          const glintsLinkRegExp = /^(http[s]?:\/\/(\S*\.)*glints\.+\w*)/;
          const isInternalLink = glintsLinkRegExp.test(url);
          return `<a href="${url}" target="${target || targetOption}" rel="${
            isInternalLink
              ? 'noopener noreferrer'
              : 'nofollow noopener noreferrer'
          }" class="glints-link">${originalText}</a>`;
        }
      } else if (entity.type === 'IMAGE') {
        const { height, src, width } = entity.data;
        return `<img src="${src}" height="${height}" width="${width}" alt="" class="glints-image" />`;
      }
      return originalText;
    },
  })(contentState);

  return <ContentContainer dangerouslySetInnerHTML={{ __html: html }} />;
};
