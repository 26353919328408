import { Breakpoints } from 'glints-aries/lib/@next';
import { NotoSans } from 'glints-aries/lib/@next/utilities/fonts/Fonts';
import styled, { css } from 'styled-components';

/**
 *  This css styled following the same design system in aries library
 *  Check this preference:
 *  @link https://github.com/glints-dev/glints-aries/blob/v4/src/@next/Typography/TypographyStyles.ts#L111
 */
export const body1 = css`
  font-family: ${NotoSans}, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0px;

  @media (max-width: ${Breakpoints.large}) {
    font-size: 14px;
  }
`;

export const ContentContainer = styled.div`
  p,
  ul,
  ol,
  li {
    ${body1}
    margin-block: 12px;
  }
`;
